import {Component, OnInit} from '@angular/core';
import {SelectTabService} from "../select-tab.service";
import {first} from "rxjs";
import {FormBuilder, FormGroup} from "@angular/forms";

export interface Request {
  source: string;
  destination: string;
  service:string;

}
const ELEMENT_DATA: Request[] = [
  {source: '1.AUTO_VLAN105_110_BW_I_GLOBAL_CLIENT', destination: 'AUTO_VLAN120_123_BW-I-TIER2_SVR_ALL', service:'tcp/66'},
];

@Component({
  selector: 'app-offenes-ticket',
  templateUrl: './offenes-ticket.component.html',
  styleUrl: './offenes-ticket.component.scss'
})
export class OffenesTicketComponent implements OnInit {
  isEditMode = false;
  form!: FormGroup;


  basisFields = [
    { label: 'Subject', name: 'subject', value: 'Example Subject' },
    { label: 'Owner', name: 'owner', value: 'TEST' },
    { label: 'Status', name: 'status', value: 'Open' },
    { label: 'Created', name: 'created', value: '2023-08-01' },
    { label: 'Requester', name: 'requester', value: 'Jane Doe' },
    { label: 'Updated', name: 'updated', value: '2023-08-05' },
    { label: 'Due', name: 'due', value: '2023-08-15' }
  ];

  generalFields = [
    { label: 'Expires', name: 'expires', value: '2023-09-01' },
    { label: 'Owing', name: 'owing', value: 'None' },
    { label: 'Role', name: 'role', value: 'Admin' },
    { label: 'All Responsible Roles', name: 'allResponsibleRoles', value: 'Team' },
    { label: 'Created', name: 'generalCreated', value: '2023-08-01' },
    { label: 'Pending Responsible', name: 'pendingResponsible', value: 'test' }
  ];

  constructor(private tabservice: SelectTabService, private fb: FormBuilder,) {
    this.tabservice.tab$.pipe(first()).subscribe(tabIndex => {
      this.selectedTab = tabIndex.tabindex;
    });

    this.tabservice.currentData.subscribe(data => {
      if (data) {
        this.subject = data.subject;
        this.owner = data.owner;
        this.status = data.status;
        this.created = data.created;
        this.requester = data.requester;
        this.updated = data.lastUpdated;
      }
    });

    this.form = this.fb.group({
      subject: [''],
      owner: [''],
      status: [''],
      created: [''],
      requester: [''],
      updated: [''],
      due: [''],
      expires: [''],
      owing: [''],
      role: [''],
      allResponsibleRoles: [''],
      generalCreated: [''],
      pendingResponsible: ['']
    });
  }

  displayedColumns: string[] = ['Source', 'Destinations', 'Service'];
  templates = ELEMENT_DATA;

  subject!: string;
  owner!: string;
  status!: string;
  created!: string;
  requester!: string;
  updated!: string;
  due!: string;
  expires!: string;
  owing!: string;
  role!: string;
  allResponsibleRoles!: string;
  generalCreated!: string;
  pendingResponsible!: string;

  selectedTab:number = 0

  ngOnInit(): void {
  this.tabservice.selectTab(2)
  }

}
